import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;

const clinicasApi = axios.create({
    baseURL: baseUrl
});

//Headers de requests
clinicasApi.interceptors.request.use(config => {

    config.headers = {
        ...config.headers,
    };

    return config;

});

export default clinicasApi;