import React from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaHeartPulse, FaUtensils, FaBriefcaseMedical, FaCircleNodes, FaWifi, FaVideo, FaFileWaveform, FaUserGroup, FaHouseLaptop } from "react-icons/fa6";

import { startService } from "../../redux/slices/service";

import styles from './Services.module.css';

export const Services = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { membresia } = useSelector(state => state.auth);

  const handleCardClic = async (modulo) => {
    let res = await dispatch(startService(modulo, membresia));
    if(res){
      navigate("/webcall");
    }
  }

  return (
    <>
      <div className='container'>
        <div className="row">
          <div className="col">
            <div className={styles.header}>Área de atención</div>
            <div className={styles.text}>Selecciona el área del especialista con el que te quieres comunicar ahora mismo. Al dar clic te enlazaremos en una videollamada con uno de nuestros expertos para atender tu duda o inquietud.</div>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-sm-12 col-md-3">
            <div className={styles.card} onClick={() => handleCardClic('C')}>
              <div className={styles.cardIconContainer}><FaHeartPulse className={styles.cardIcon} /></div>
              <div className={styles.cardHeader}>Enfermería</div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            <div className={styles.card} onClick={() => handleCardClic('N')}>
              <div className={styles.cardIconContainer}><FaUtensils className={styles.cardIcon} /></div>
              <div className={styles.cardHeader}>Nutrición</div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            <div className={styles.card} onClick={() => handleCardClic('P')}>
              <div className={styles.cardIconContainer}><FaCircleNodes className={styles.cardIcon} /></div>
              <div className={styles.cardHeader}>Cambio de hábitos</div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            <div className={styles.card} onClick={() => handleCardClic('M')}>
              <div className={styles.cardIconContainer}><FaBriefcaseMedical className={styles.cardIcon} /></div>
              <div className={styles.cardHeader}>Médico</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className={styles.infoHeader}>Tips para video consulta</div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-sm-12 col-md">
            <div className={styles.cardIconContainer}><FaWifi className={styles.infoIcon} /></div>
            <div className={styles.infoSubheader}>Conexión estable</div>
            <div className={styles.infoText}>Asegúrate de tener un buen internet.</div>
          </div>
          <div className="col-sm-12 col-md">
            <div className={styles.cardIconContainer}><FaVideo className={styles.infoIcon} /></div>
            <div className={styles.infoSubheader}>Cámara y micrófono</div>
            <div className={styles.infoText}>Actívalos para que el especialista pueda verte y escucharte.</div>
          </div>
          <div className="col-sm-12 col-md">
            <div className={styles.cardIconContainer}><FaFileWaveform className={styles.infoIcon} /></div>
            <div className={styles.infoSubheader}>Documentos listos</div>
            <div className={styles.infoText}>Ten a la mano tus resultados y medicamentos.</div>
          </div>
          <div className="col-sm-12 col-md">
            <div className={styles.cardIconContainer}><FaHouseLaptop className={styles.infoIcon} /></div>
            <div className={styles.infoSubheader}>Espacio privado</div>
            <div className={styles.infoText}>Busca un lugar sin ruidos ni distracciones.</div>
          </div>
          <div className="col-sm-12 col-md">
            <div className={styles.cardIconContainer}><FaUserGroup className={styles.infoIcon} /></div>
            <div className={styles.infoSubheader}>Acompañante opcional</div>
            <div className={styles.infoText}>Un familiar puede apoyarte si lo prefieres.</div>
          </div>
        </div>
      </div>
    </>
  )
}
