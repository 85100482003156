import React from 'react'

export const Footer = () => {
  return (
    <>
        <div className="container">
            <footer className="">
                <p className="text-center text-body-secondary">© { new Date().getFullYear() } Clinicas del Azúcar</p>
            </footer>
        </div>
    </>
  )
}
