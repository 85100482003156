import {
    createBrowserRouter,
    Navigate,
    RouterProvider
  } from "react-router-dom";
  
  import { AuthLayout } from "../pages/Layout";
  import {
    Error,
    Login,
  } from "../pages";
  
  const router = createBrowserRouter([
    {
      path: "",
    //   element: <Root/>,
      errorElement: <Error />,
      children: [
        {
          path: "",
          element: <AuthLayout />,
          children: [
            {
              path: "",
              element: <Login />,
            }
          ]
        },
        {
          path: "/*",
          element: <Navigate to="" replace={true} />,
        }
      ],
    },
  ]);
  
  export const PublicRouter = () => {
    return (
      <>
        <RouterProvider router={router} />
      </>
    )
  }
  