import { useSelector } from "react-redux";

import { PrivateRouter } from "./";
import { PublicRouter } from "./";

import { NotificationsSystem } from "../components";

export const AppRouter = () => {

    const { status } = useSelector(state => state.auth);

    return (
        <>
            <NotificationsSystem />
            {status === 'auto-checking' ? <div>Autenticación de usuario...</div> : (status === 'authenticated' ? <PrivateRouter /> : <PublicRouter />) }
        </>
    )
}
