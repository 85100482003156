import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';

import { startLogout } from '../../../../redux/slices/auth';

import styles from './Navbar.module.css';

export const Navbar = () => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const carpetButton = useRef(null);

    const { membresia } = useSelector(state => state.auth);

    const handleMovilMenuContract = () => {
        carpetButton.current?.click();
    }

    const handleLogout = () => {
        dispatch(startLogout());
        navigate("/");
    }

    return (
        <>
            <nav className={"navbar fixed-top bg-body-tertiary p-3 " + styles.navbar}>
                <div className={"container-fluid " + styles.navbarContainerFluid}>
                    <Link to="/" className={styles.navbarMovil}>
                        <div className={styles.navbarLogo} />
                    </Link>
                    <button className={"navbar-toggler " + styles.navbarMovil} type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation" ref={carpetButton}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={"collapse navbar-collapse " + styles.navbarMovil} id="navbarTogglerDemo02">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className={styles.navbarDropdownMenuItem} onClick={handleMovilMenuContract}><div className={styles.navbarLogoutMovil} onClick={handleLogout}>Logout ({ membresia })</div></li>
                        </ul>
                    </div>
                    <div className={"row justify-content-around " + styles.navbarWeb}>
                        <div className="col">
                            <div className="d-flex">
                                <Link to="/">
                                    <div className={styles.navbarLogo} />
                                </Link>
                            </div>
                        </div>
                        <div className="col d-flex justify-content-center"></div>
                        <div className="col d-flex justify-content-end">
                            <div className={styles.logoutIconContainer}>
                                { membresia } <Icon.BoxArrowRight className={styles.logoutIcon} onClick={handleLogout} />
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}
