import { useRouteError } from "react-router-dom";

export const Error = () => {
    
    const error = useRouteError();
    console.error(error);

    let text = 'Error';
    if(error !== null && typeof error.statusText !== 'undefined'){
        text = error.statusText;
    }else if(error !== null && typeof error.message !== 'undefined'){
        text = error.message;
    }

    return (
        <>
            <div className="allergien-index-name">Inmunet</div>
            <div className="allergien-index-text-1 mt-5">Oops!</div>
            <div className="allergien-index-text-2 mt-1">Lo sentimos, un error inesperado ha ocurrido.</div>
            <div className="allergien-index-text-3">{text}</div>
        </>
    )
}
