import { createSlice } from '@reduxjs/toolkit';

export const serviceSlice = createSlice({
    name: 'service',
    initialState: {
        modulo: null, // ['C', 'N', 'P', 'M]
        membresia: null,
        idCita: null,
        idReunionZoom: null,
        passwordReunionZoom: null,
        urlReunionZoom: null
    },
    reducers: {
        setService: (state, { payload }) => {
            state.modulo = payload.modulo;
            state.membresia = payload.membresia;
            state.idCita = payload.idCita;
            state.idReunionZoom = payload.idReunionZoom;
            state.passwordReunionZoom = payload.passwordReunionZoom;
            state.urlReunionZoom = payload.urlReunionZoom;
        },
        clearService: (state, { payload }) => {
            state.modulo = null;
            state.membresia = null;
            state.idCita = null;
            state.idReunionZoom = null;
            state.passwordReunionZoom = null;
            state.urlReunionZoom = null;
        },
    }
});


// Action creators are generated for each case reducer function
export const { setService, clearService } = serviceSlice.actions;