import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";

export const WebCall = () => {

  const navigate = useNavigate();

  const sdkKey = process.env.REACT_APP_ZOOM_SDK_KEY;
  const sdkSecret = process.env.REACT_APP_ZOOM_SDK_SECRET;
  const { membresia, idReunionZoom: meetingNumber, passwordReunionZoom: passWord } = useSelector(state => state.service);
  const role = 0;
  const userName = String(membresia);
  // const userEmail = "example@mail.com";
  const leaveUrl = "/";

  useEffect(() => {
    if(typeof membresia === 'undefined' || membresia === '' || membresia === null || typeof meetingNumber === 'undefined' || meetingNumber === '' || meetingNumber === null || typeof passWord === 'undefined' || passWord === '' || passWord === null){

      navigate("/", { replace: true });

    }else{

      async function initZoom(){
  
        const { ZoomMtg } = await import('@zoom/meetingsdk');
    
        // ZoomMtg.setZoomJSLib("https://source.zoom.us/3.9.2/lib", "/av");
  
        ZoomMtg.preLoadWasm();
        ZoomMtg.prepareWebSDK();
  
        ZoomMtg.generateSDKSignature({
          meetingNumber,
          role,
          sdkKey,
          sdkSecret,
          success: function(signature){
  
            ZoomMtg.init({
              leaveUrl,
              patchJsMedia: true,
              leaveOnPageUnload: true,
              success: function(data){
  
                ZoomMtg.join({
                  meetingNumber,
                  signature: signature,
                  sdkKey,
                  userName,
                  // userEmail,
                  passWord,
                  success: function(){
  
                    console.log('-- Joined --');
  
                  },
                  error: function(error){
  
                    console.error(error);
  
                  }
                });
  
              },
              error: function(error){

                console.error(error);
  
              }
            });
  
          },
          error: function(error){
  
            console.error(error);
  
          }
        });
  
      }
      initZoom();

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <></>
    // <div>
    //   <h1>Zoom Meeting SDK Sample React</h1>
    // </div>
  )
}
