import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        status: 'not-authenticated', // ['auto-checking', 'not-authenticated', 'checking', 'authenticated]
        membresia: null,
        errorMessage: undefined
    },
    reducers: {
        login: (state, { payload }) => {
            state.status = 'authenticated';
            state.membresia = payload.membresia;
            state.errorMessage = undefined;
        },
        logout: (state, { payload }) => {
            state.status = 'not-authenticated';
            state.membresia = null;
            state.errorMessage = (typeof payload !== 'undefined') ? payload.msg : undefined;
        },
        checking: (state) => {
            state.status = 'checking';
        },
    }
});


// Action creators are generated for each case reducer function
export const { login, logout, checking } = authSlice.actions;