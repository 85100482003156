import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        notifications: [
            // {
            //     id: '', // moment().unix()
            //     content: '',
            //     type: '', // 'success', 'info', 'warning', 'danger'
            // }
        ]
    },
    reducers: {
        addNotification: (state, { payload }) => {
            state.notifications = [
                ...state.notifications,
                {
                    id: payload.id,
                    content: payload.content,
                    type: payload.type,
                }
            ];
        },
        removeNotification: (state, { payload }) => {
            state.notifications = state.notifications.filter(noty => noty.id !== payload);
        },
        clearNotifications: (state) => {
            state.notifications = [];
        },
    }
});


// Action creators are generated for each case reducer function
export const { addNotification, removeNotification, clearNotifications } = uiSlice.actions;