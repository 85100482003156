import React from 'react'

import styles from './Footer.module.css';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <>
        <div className={styles.footerContainer}>
            <div className="container">
                <footer className={"d-flex flex-wrap justify-content-between align-items-center " + styles.footerWeb}>
                    <div className="col-md-4 d-flex align-items-center">
                        <Link to="/">
                            <div className={styles.footerLogo} />
                        </Link>
                    </div>

                    <div className="col-md-4 justify-content-end text-end">
                        <span className="mb-3 mb-md-0">© { new Date().getFullYear() } Clinicas del Azúcar</span>
                    </div>
                </footer>
                <footer className={styles.footerMovil}>
                    <div className="row">
                        <div className="col">
                            <div className="centrar">
                                <Link to="/">
                                    <div className={styles.footerLogo} />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            <span className="mb-3 mb-md-0">© { new Date().getFullYear() } Clinicas del Azúcar</span>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    </>
  )
}
