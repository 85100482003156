import {
    createBrowserRouter,
    Navigate,
    RouterProvider
  } from "react-router-dom";
  
  import { PrivateLayout } from "../pages/Layout";
  import {
    Error,
    Services,
    WebCall,
  } from "../pages";
  
  const router = createBrowserRouter([
    {
      path: "",
      element: <PrivateLayout />,
      errorElement: <Error />,
      children: [
        {
          path: "",
          element: <Services />,
        },
        {
          path: "/services",
          element: <Services />,
        },
        {
          path: "/webcall",
          element: <WebCall />,
        },
        {
          path: "/*",
          element: <Navigate to="" />,
        }
      ],
    },
  ]);
  
  export const PrivateRouter = () => {
    return (
      <>
        <RouterProvider router={router} />
      </>
    )
  }