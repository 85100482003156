import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './FormFields.module.css';

export const MembresiaInput = ({ formFieldStatus='checked', label, name, onChange, placeholder, required = false, requiredIndicator = false, setFormFieldValidation, value }) => {

    const [inputValidation, setInputValidation] = useState({ isValid: null, msg: null });
    const [hasChange, setHasChange] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleInputValidation = useCallback(() => {
        if((value > 999999999 && value < 10000000000) || !hasChange){
            setInputValidation({ isValid: true, msg: null });
            if(setFormFieldValidation) setFormFieldValidation(name, true);
        }else{
            if(value !== ''){
                setInputValidation({ isValid: false, msg: label + ' no válida' });
                if(setFormFieldValidation) setFormFieldValidation(name, false);
            }else{
                if(required){
                    setInputValidation({ isValid: false, msg: label + ' no válida' });
                    if(setFormFieldValidation) setFormFieldValidation(name, false);
                }else{
                    setInputValidation({ isValid: true, msg: null });
                    if(setFormFieldValidation) setFormFieldValidation(name, true);
                }
            }
        }
    })

    const handleOnChange = (e) => {
        setHasChange(true);
        onChange(e);
    }

    useEffect(() => {
        handleInputValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useEffect(() => {
        if(formFieldStatus === 'checking') handleInputValidation();
    }, [formFieldStatus, handleInputValidation]);
  
    return (
        <>
        <label htmlFor={ name } className={styles.customInputLabel}>{ label }{ requiredIndicator && <span className={styles.requiredIndicator}> *</span> }</label>
        <input type="number" className={"form-control " + styles.customInputBasic + ((inputValidation.isValid != null && !inputValidation.isValid) ? " " + styles.customErrorInputBasic : "")} placeholder={ placeholder } name={ name } value={ value } onChange={ handleOnChange } required={required} min={0} autoComplete="off" />
        <div className={styles.helperErrorText + ((inputValidation.isValid != null && !inputValidation.isValid) ? "" : " d-none" )}>{(inputValidation.isValid != null && !inputValidation.isValid) && inputValidation.msg}</div>
        </>
    )
}

MembresiaInput.propTypes = {
  formFieldStatus: PropTypes.oneOf(['checked', 'checking']),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  requiredIndicator: PropTypes.bool,
  setFormFieldValidation: PropTypes.func,
  value: PropTypes.any,
}