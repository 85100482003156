import { clinicasApi } from '../../../api';
import { redirect } from "react-router-dom";
import { login, logout, checking } from './authSlice';
import { successNotification, dangerNotification } from '../ui';
import { clearNotifications } from '../ui/uiSlice';
import { clearService } from '../service/serviceSlice';

export const startLogin = (membresia, password) => {
    return async(dispatch) => {

        dispatch(checking());

        try {

            const { data } = await clinicasApi.post('/zoom/session/', {
                membresia,
                password
            });

            if (data.status) {

                dispatch(login(data));
                redirect("/login");
                dispatch(clearNotifications());
                dispatch(successNotification('Usuario autenticado'));

            } else {

                dispatch(logout(data));
                dispatch(clearService());
                dispatch(dangerNotification(data.msg));

            }

        } catch (error) {

            if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined' && typeof error.response.data.msg !== 'undefined') {

                dispatch(logout(error.response.data));
                dispatch(clearService());
                dispatch(dangerNotification(error.response.data.msg));

            } else {

                dispatch(logout({ msg: 'Error al procesar la petición, intenta nuevamente.' }));
                dispatch(clearService());
                dispatch(dangerNotification('Error al procesar la petición, intenta nuevamente.'));

            }

        }

    }
}

export const startLogout = () => {
    return async(dispatch) => {

        dispatch(logout());
        dispatch(clearService());
        dispatch(clearNotifications());
        dispatch(successNotification('Sesión terminada'));

    }
}