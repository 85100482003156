import { clinicasApi } from '../../../api';
import { setService, clearService } from './serviceSlice';
import { successNotification, dangerNotification } from '../ui';
import { clearNotifications } from '../ui/uiSlice';

export const startService = (modulo, membresia) => {
    return async(dispatch) => {

        try {

            dispatch(successNotification('Conectando...'));

            const { data } = await clinicasApi.post('/zoom/solicita-atencion/', {
                token: "c19d325fd77e65d33a336e8b3ca95100",
                membresia,
                modulo
            });

            if (data.status) {

                dispatch(setService({...data, modulo }));
                dispatch(clearNotifications());
                dispatch(successNotification('Redirigiendo'));

                return true;

            } else {

                dispatch(clearService());
                dispatch(clearNotifications());
                dispatch(dangerNotification(data.msg));

            }

            return false;

        } catch (error) {

            if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined' && typeof error.response.data.msg !== 'undefined') {

                dispatch(clearService());
                dispatch(clearNotifications());
                dispatch(dangerNotification(error.response.data.msg));

            } else {

                dispatch(clearService());
                dispatch(clearNotifications());
                dispatch(dangerNotification('Error al procesar la petición, intenta nuevamente.'));

            }

            return false;

        }

    }
}