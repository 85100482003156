import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useForm, useFormValidation } from "../../hooks"
import { startLogin } from "../../redux/slices/auth";

import { MembresiaInput, PasswordInput, PrimaryButton } from "../../components";

import styles from './Login.module.css';

export const Login = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { status } = useSelector(state => state.auth);

  const { membresia, password, onInputChange } = useForm({ membresia: '', password: '' });

  const { isFormValid, formValidationStatus, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'membresia', default: false}, {field: 'password', default: false}]);

  const onSubmit = async ( event ) => {
    event.preventDefault();
    
    startFormValidation();
    
    if(formValidationStatus === 'checked' && isFormValid != null && isFormValid){
      await dispatch(startLogin(membresia, password));
    }

  }

  useEffect(() => {
    navigate("", { replace: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles.loginContainer}>
        <div className="row mb-4">
          <div className="col-12">
            <div className={styles.formHeader}>Bienvenido</div>
          </div>
          <div className="col-12">
            <div className="text-center">Ingresa tus datos para iniciar  tu video llamada</div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <form onSubmit={ onSubmit }>
              <div className="row">
                <div className="col">
                  <MembresiaInput
                    formFieldStatus={ formFields['membresia'].status }
                    label="Membresia"
                    name="membresia"
                    onChange={ onInputChange }
                    placeholder="Membresia"
                    required={true}
                    requiredIndicator={false}
                    setFormFieldValidation={ setFormFieldValidation }
                    value={ membresia }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <PasswordInput
                    formFieldStatus={ formFields['password'].status }
                    label="Contraseña"
                    name="password"
                    onChange={ onInputChange }
                    placeholder="Contraseña"
                    required={true}
                    requiredIndicator={false}
                    setFormFieldValidation={ setFormFieldValidation }
                    value={ password }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <PrimaryButton
                    block={true}
                    disabled={status === 'checking' || formValidationStatus === 'checking'}
                    text="Ingresar"
                    type="submit"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
