import { Link } from "react-router-dom";

import styles from './Navbar.module.css';

export const Navbar = () => {

    return (
        <>
            <nav className={"navbar fixed-top bg-body-tertiary p-3 " + styles.navbar}>
                <div className={"container-fluid " + styles.navbarContainerFluid}>
                    <Link to="/" className={styles.navbarMovil}>
                        <div className={styles.navbarLogo} />
                    </Link>
                    <div className={"row justify-content-around " + styles.navbarWeb}>
                        <div className="col">
                            <div className="d-flex">
                                <Link to="/">
                                    <div className={styles.navbarLogo} />
                                </Link>
                            </div>
                        </div>
                        <div className="col d-flex justify-content-center"></div>
                        <div className="col d-flex justify-content-end"></div>
                    </div>
                </div>
            </nav>
        </>
    )
}
